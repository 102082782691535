document.addEventListener("DOMContentLoaded", function() {
	"use strict";

	var i = document.getElementById("inhalt");
	var loginmenu = document.getElementById("loginmenu");
	var loginToggle = document.getElementById("logintoggle");
	var loginClose = document.getElementById("close-loginmenu");
    var bodyContainer = document.getElementById("body-container");
	var header = document.getElementById("fu-header");
	var body = document.body;
	var showingClass = "loginmenu-showing";
    var mobileSideNavShowingClass = "mobilemenu-showing";
	
//	function setTabIndex(selector, index) {
//		var links = document.querySelectorAll(selector);
//		for (var i=0, l=links.length; i < l; i++) {
//			var link = links[i];
//			link.tabIndex = index;
//		}
//	}
//	
//	function setTabOrder() {
//		//Index 1 für Kopfbereich, Index 2 für Side-Menu reserviert, beides wird von sidemenu.js gesetzt.
//		setTabIndex("#loginmenu a", 3);
//		//Rest der Seite soll dann Index haben, d.h. auf diese drei Gruppen folgen.
//	}
	
	function alTop() {
		//Sollhöhe: Fenster soweit hochscrollen, bis der fu-header (Logos) gerade aus dem Bild gescrollt ist,
		//also der top-bar (selbst bei non-sticky position) genau am oberen Viewport-Rand liegt.
		//(Das wird am header statt am top-bar festgemacht, da letzterer ja sticky sein kann, immer am oberen
		//Viewport-Rand kleben bleibt beim Nach-Unten-Scrollen, so dass er in dem Zustand nicht mehr als
		//Bezugspunkt taugt.)
		var top = header.offsetTop + header.offsetHeight;
		//Nur scrollen, wenn Soll (top) < Ist (also nicht runterscrollen, wenn die Logos sichtbar sind):
		if (window.scrollY > top)
			window.scrollTo(0, top);
	}
	
	function alLeft() {
		//Zuerst eine ggf. früher per Script gesetzte left-Property zurücksetzen,
		//da das Fenster inzwischen resized worden sein könnte. Dann neu berechnen.
		loginmenu.style.left = null;
		//Das Menü ist rechtsbündig am Bildschirm/Fensterrand.
		//Sollte das Fenster aber so breit sein, dass das Menü weiter rechts angeordnet ist als der Toggle,
		//ordne es auch linksbündig unter dem Toggle (und weiter rechtsbündig am Rand) an und
		//erlaube dafür eine Auto-Breite (strecke es also):
		var maxLeft = Math.trunc(loginToggle.getBoundingClientRect().left);
		//für maxLeft nicht offsetLeft verwenden, weil offetLeft nicht relativ zum Viewport, sondern
		//relativ zum "offsetParent" berechnet wird. Das stimmt hier zwar ggf. überein, aber nur,
		//so lange unter den Vorfahren keiner position:relative o.ä hat (was aber bei li.selected z.B. der Fall ist).
		//Notiz: I. Ggs. zu offsetLeft sind die Werte von BoundingClientRect Floats. 
		//Damit könnte man zwar auch arbeiten, aber eine CSS-Positionierung um px mit Nachkommastellen wäre nicht wirklich nötig/sinnvoll.
		var currLeft = loginmenu.offsetLeft;
		//Hier sollte der OffsetParent immer die Viewportbreite haben, da klappt das auch mit offsetLeft.
		if (currLeft > maxLeft) {
			loginmenu.style.left = "" + maxLeft + "px";
//			loginmenu.style.width = "auto"; //Gilt derzeit fix laut CSS.
		}
	}
	
	function showLoginmenu() {
		alTop();
		alLeft();
		loginmenu.classList.remove("no-transition");
        body.classList.remove(mobileSideNavShowingClass); //Falls das Seiten-Overlaymenü offen sein sollte, schließe das zunächst.
		body.classList.add(showingClass); //Am Body, damit per CSS auch andere Dinge wie der Menüpunkt formatiert werden können.
		loginToggle.setAttribute("aria-expanded", "true");
	}
	
	function hideLoginmenu() {
		body.classList.remove(showingClass);
		loginToggle.setAttribute("aria-expanded", "false");
	}
	
	function loginmenuShowing() {
		return body.classList.contains(showingClass);
	}
	
	if (loginClose) { //Eventhandler für close-Icon registrieren, sofern dieses im Dokument gefunden wurde.
		loginClose.addEventListener("click", hideLoginmenu);
	}
	
	if (loginToggle && loginmenu) { //Eventhandler für Login-Menüpunkt registrieren, sofern dieser im Dokument gefunden wurde.
		try {
			loginToggle.setAttribute("aria-expanded", "false");
			loginToggle.addEventListener("click", function(e) {
				if (loginmenu) {
					if (!loginmenuShowing()) {
						showLoginmenu();
						try {
							i.addEventListener("click", hideLoginmenu, {once: true});
						} catch (ex) {
							// eslint-disable-next-line no-console
							console.log("addEventListener(ev, listener, options) unsupported. Menu must be closed by clicking toggle.");
						}
					} else {
						hideLoginmenu();
						i.removeEventListener("click", hideLoginmenu);
					}
				}
				e.preventDefault();
			});
			loginmenu.className = "js-enabled no-transition";
			//Nun zwecks vernüftigerer Tabreihenfolge das Menü vom Body-Ende an den Body-Anfang verschieben:
            bodyContainer.insertAdjacentElement("afterbegin", loginmenu);
		} catch (ex) {
	//		console.log(ex);
		}
	}
});